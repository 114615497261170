
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93jDgz6Q7vMsMeta } from "/srv/website/pages/blog/[slug].vue?macro=true";
import { default as index9lNg35cxZkMeta } from "/srv/website/pages/blog/index.vue?macro=true";
import { default as contactumzzQgZ9f2Meta } from "/srv/website/pages/contact.vue?macro=true";
import { default as electronicsCELGbEIYZsMeta } from "/srv/website/pages/electronics.vue?macro=true";
import { default as _404sFhaDk3rz8Meta } from "/srv/website/pages/error/404.vue?macro=true";
import { default as faqbvCaMk3Du8Meta } from "/srv/website/pages/faq.vue?macro=true";
import { default as handyswNtw4A6qW1Meta } from "/srv/website/pages/handys.vue?macro=true";
import { default as indexauuitsYJu3Meta } from "/srv/website/pages/index.vue?macro=true";
import { default as jobsaaXmN5F7V6Meta } from "/srv/website/pages/jobs.vue?macro=true";
import { default as legalY8nl3n5XfSMeta } from "/srv/website/pages/legal.vue?macro=true";
import { default as login777aTIUAiSMeta } from "/srv/website/pages/login.vue?macro=true";
import { default as berlinTbWPltRhnXMeta } from "/srv/website/pages/old-clothes/berlin.vue?macro=true";
import { default as bonn3FpG603ksUMeta } from "/srv/website/pages/old-clothes/bonn.vue?macro=true";
import { default as cologneVuPZ9rC7VbMeta } from "/srv/website/pages/old-clothes/cologne.vue?macro=true";
import { default as frankfurtXBnX3YsT4xMeta } from "/srv/website/pages/old-clothes/frankfurt.vue?macro=true";
import { default as hamburgMhpQGpgq2pMeta } from "/srv/website/pages/old-clothes/hamburg.vue?macro=true";
import { default as index9fURhySR4RMeta } from "/srv/website/pages/old-clothes/index.vue?macro=true";
import { default as munich163FFbv2HDMeta } from "/srv/website/pages/old-clothes/munich.vue?macro=true";
import { default as confirmationgMXsRObATeMeta } from "/srv/website/pages/order/confirmation.vue?macro=true";
import { default as indexQfZ3EMY3bDMeta } from "/srv/website/pages/order/index.vue?macro=true";
import { default as thank_45youIiMv6wjsjoMeta } from "/srv/website/pages/order/thank-you.vue?macro=true";
import { default as tobytigerQjmK4TNqWfMeta } from "/srv/website/pages/partners/tobytiger.vue?macro=true";
import { default as privacynHR7ZEX8fIMeta } from "/srv/website/pages/privacy.vue?macro=true";
import { default as recycleablesNVAim536mCMeta } from "/srv/website/pages/recycleables.vue?macro=true";
import { default as registermykfPA5EoVMeta } from "/srv/website/pages/register.vue?macro=true";
import { default as reset_45passwordufSoUydTv2Meta } from "/srv/website/pages/reset-password.vue?macro=true";
import { default as detailsKGInT8y3RHMeta } from "/srv/website/pages/single/selfservice/details.vue?macro=true";
import { default as indexm919wjN412Meta } from "/srv/website/pages/single/selfservice/index.vue?macro=true";
import { default as social_45projectsg0R8X4GSS1Meta } from "/srv/website/pages/social-projects.vue?macro=true";
import { default as teamBh9n8dEMBTMeta } from "/srv/website/pages/team.vue?macro=true";
import { default as termsxY3dMBTJtEMeta } from "/srv/website/pages/terms.vue?macro=true";
import { default as dashboardGvWs3YtVJoMeta } from "/srv/website/pages/user/dashboard.vue?macro=true";
import { default as dataLvw4X2Bm54Meta } from "/srv/website/pages/user/data.vue?macro=true";
import { default as invoicesiWOLoz2nlqMeta } from "/srv/website/pages/user/invoices.vue?macro=true";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/srv/website/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/srv/website/pages/blog/index.vue")
  },
  {
    name: "contact",
    path: "/kontakt",
    component: () => import("/srv/website/pages/contact.vue")
  },
  {
    name: "electronics",
    path: "/elektrogeraete",
    component: () => import("/srv/website/pages/electronics.vue")
  },
  {
    name: "error-404",
    path: "/:pathMatch(.*)*",
    component: () => import("/srv/website/pages/error/404.vue")
  },
  {
    name: "faq",
    path: "/haeufige-fragen",
    component: () => import("/srv/website/pages/faq.vue")
  },
  {
    name: "handys",
    path: "/handys",
    component: () => import("/srv/website/pages/handys.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/srv/website/pages/index.vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/srv/website/pages/jobs.vue")
  },
  {
    name: "legal",
    path: "/impressum",
    component: () => import("/srv/website/pages/legal.vue")
  },
  {
    name: "login",
    path: "/anmelden",
    component: () => import("/srv/website/pages/login.vue")
  },
  {
    name: "old-clothes-berlin",
    path: "/old-clothes/berlin",
    component: () => import("/srv/website/pages/old-clothes/berlin.vue")
  },
  {
    name: "old-clothes-bonn",
    path: "/old-clothes/bonn",
    component: () => import("/srv/website/pages/old-clothes/bonn.vue")
  },
  {
    name: "old-clothes-cologne",
    path: "/old-clothes/cologne",
    component: () => import("/srv/website/pages/old-clothes/cologne.vue")
  },
  {
    name: "old-clothes-frankfurt",
    path: "/altkleider/frankfurt",
    component: () => import("/srv/website/pages/old-clothes/frankfurt.vue")
  },
  {
    name: "old-clothes-hamburg",
    path: "/altkleider/hamburg",
    component: () => import("/srv/website/pages/old-clothes/hamburg.vue")
  },
  {
    name: "old-clothes",
    path: "/altkleider",
    component: () => import("/srv/website/pages/old-clothes/index.vue")
  },
  {
    name: "old-clothes-munich",
    path: "/old-clothes/munich",
    component: () => import("/srv/website/pages/old-clothes/munich.vue")
  },
  {
    name: "order-confirmation",
    path: "/bestellen/ueberpruefen",
    component: () => import("/srv/website/pages/order/confirmation.vue")
  },
  {
    name: "order",
    path: "/bestellen",
    component: () => import("/srv/website/pages/order/index.vue")
  },
  {
    name: "order-thank-you",
    path: "/bestellen/danke",
    component: () => import("/srv/website/pages/order/thank-you.vue")
  },
  {
    name: "partners-tobytiger",
    path: "/tobytiger",
    component: () => import("/srv/website/pages/partners/tobytiger.vue")
  },
  {
    name: "privacy",
    path: "/datenschutz",
    component: () => import("/srv/website/pages/privacy.vue")
  },
  {
    name: "recycleables",
    path: "/recycleables",
    component: () => import("/srv/website/pages/recycleables.vue")
  },
  {
    name: "register",
    path: "/registrieren",
    component: () => import("/srv/website/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/passwort-zuruecksetzen",
    component: () => import("/srv/website/pages/reset-password.vue")
  },
  {
    name: "single-selfservice-details",
    path: "/selfservice/details",
    component: () => import("/srv/website/pages/single/selfservice/details.vue")
  },
  {
    name: "single-selfservice",
    path: "/selfservice",
    component: () => import("/srv/website/pages/single/selfservice/index.vue")
  },
  {
    name: "social-projects",
    path: "/soziale-projekte",
    component: () => import("/srv/website/pages/social-projects.vue")
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/srv/website/pages/team.vue")
  },
  {
    name: "terms",
    path: "/agb",
    component: () => import("/srv/website/pages/terms.vue")
  },
  {
    name: "user-dashboard",
    path: "/kunde/uebersicht",
    component: () => import("/srv/website/pages/user/dashboard.vue")
  },
  {
    name: "user-data",
    path: "/kunde/daten",
    component: () => import("/srv/website/pages/user/data.vue")
  },
  {
    name: "user-invoices",
    path: "/kunde/rechnungen",
    component: () => import("/srv/website/pages/user/invoices.vue")
  }
]