import revive_payload_client_4sVQNw7RlN from "/srv/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/srv/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/srv/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/srv/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/srv/website/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/srv/website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/srv/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/srv/website/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/srv/website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/srv/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/srv/website/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import features_client_YPYVe3oNsF from "/srv/website/plugins/features.client.js";
import floating_vue_Grexwy95Ly from "/srv/website/plugins/floating-vue.js";
import formkit_vd6cxTrtLJ from "/srv/website/plugins/formkit.js";
import mode_sXiL06qItE from "/srv/website/plugins/mode.js";
import persistedState_client_EbVDgvQAtN from "/srv/website/plugins/persistedState.client.js";
import resize_client_JYetQn3Ixt from "/srv/website/plugins/resize.client.js";
import scrollOnNavigation_client_OVIjPYNI0Q from "/srv/website/plugins/scrollOnNavigation.client.js";
import index_jdL8Aj7Xyl from "/srv/website/plugins/tracker/index.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  features_client_YPYVe3oNsF,
  floating_vue_Grexwy95Ly,
  formkit_vd6cxTrtLJ,
  mode_sXiL06qItE,
  persistedState_client_EbVDgvQAtN,
  resize_client_JYetQn3Ixt,
  scrollOnNavigation_client_OVIjPYNI0Q,
  index_jdL8Aj7Xyl
]